<template>
  <section class="blank-page">
    <pageLoader v-if="showloader" :loadingText="'Traitement des données en cours'"/>
    <div class="page-header">
      <h3 class="page-title"> Ajout d'une service </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Produit</a></li>
          <li class="breadcrumb-item active" aria-current="page">Ajout d'une service</li>
        </ol>
      </nav>
    </div>
      <div class="row">
          <div class="col-md-12">
              <div class="card">
                    <div class="card-body">
                        <!-- ajout service -->
                        <div class="row">
                          <div class="col-md-6">
                            <form class="forms-sample" @submit.prevent="handleSubmit">
                             <b-form-group label="Catégories" label-for="tags-component-select">
                                    <b-form-select v-model="category" :options="categories" value-field="ulid"
                                        text-field="category"></b-form-select>
                                </b-form-group>
                              <!-- service name -->
                              <!-- tags -->
                              <div class="form-group"> 
                                <label for="Tags">Tags:</label>
                                <input list="tagslist" 
                                class="form-control"
                                autocomplete="off"
                                  v-model="tags"
                                  :state="$v.tags.$dirty ? !$v.tags.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                 name="Tags" id="Tags">

                                <datalist id="tagslist">
                                  <option v-for="(item, index) in tagsservice" :key="index">{{item}}</option>
                                </datalist>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </div>
                                <!-- end tags -->
                              <b-form-group id="service" label="Nom de du service" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="name"
                                  :state="$v.name.$dirty ? !$v.name.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group id="price" label="Prix" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="price"
                                  :state="$v.price.$dirty ? !$v.price.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>  
                              
                              <!-- duration -->
                              <b-form-group id="duration" label="Durée (hh:mm)" label-for="example-input-1">
                                <b-form-timepicker 
                                  v-model="duration"
                                  id="example-input-1"
                                  name="example-input-1"
                                  minutes-step="5"
                                  no-close-button
                                  :state="$v.duration.$dirty ? !$v.duration.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                   locale="fr">
                                  </b-form-timepicker>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- description -->
                              <b-form-group id="description" label="Description" label-for="example-input-1">
                                <b-form-textarea
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="description"
                                  rows="3"
                                  :state="$v.description.$dirty ? !$v.description.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-textarea>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <div class="d-flex">
                                <b-button type="submit" variant="success" class="mr-2">Valider</b-button>
                                <b-button variant="light" class="mr-2" @click="$router.go(-1) && showSnotify()">Annuler</b-button>
                              </div>
                            </form>
                            <div v-if="isload" class="jumping-dots-loader">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                          </div>
                          </div>
                        </div>
                      <!-- Modal Ends -->
                    </div>
                    <!-- <button @click="showSnotify()">cliquer pour la notification</button> -->
                    <vue-snotify></vue-snotify>
                  </div>
          </div>
      </div>
  </section>

</template>

<script>
const axios = require ('axios').default
import { validationMixin } from 'vuelidate'
// import { required, minLength, alphaNum, numeric } from 'vuelidate/lib/validators'
import { required, minLength,decimal} from 'vuelidate/lib/validators'
// import alertPrompt from '../../components/alerts/sweet-alert/alertPrompt.vue'
import pageLoader from '../../components/pageLoader.vue'

export default {
  components: { pageLoader },
  name: 'addCatalogue',
  mixins: [validationMixin],
  data () {
      return {
          ulid:null,
          showloader: false,
          isload:false,
          name:'',
          price:'',
          duration:'00:00',
          tags:'',
          description:'',
          categories:[],
          category:'',
          tagsservice:[],
          value:''
      }
  },
  validations: {
    name: {
      required,
      minLength: minLength(4)
    },
    description: {
      required

    },
    tags: {
      required
    },
    price: {
      required,
      decimal
    },
    duration: {
      required,
    }
  },
  computed: {
  },
  methods: { 
    // add service
     async handleSubmit() {
       console.log("hello");
       this.$v.$touch()
        if (this.$v.$anyError) {
          return
        }
        this.showloader = true
        if ( !this.ulid) {
          console.log('post')
           axios.post('services',
      {
          category:this.category,
          name:this.name,
          tags:[this.tags],
          price:Number(this.price),
          duration:Number(this.TimeToFloat(this.duration)),
          description:this.description
      },
      {
          headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              "Content-Type": "application/json"
          }
      })
      .then (res => {
        res
        this.showSnotify()
        this.$router.push( { name: 'service'})
      } 
      )
      .catch (err => console.log ('response err service', err))
        }
        // PUT
        else{
          // alert('PUT')
          axios.put(`services/${this.ulid}`, {
          category:this.category,
          name:this.name,
          tags:[this.tags],
          price:Number(this.price),
          duration:Number(this.TimeToFloat(this.duration)),
          description:this.description
          },
          {
          headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
          }
      })
          .then(()=> {
            this.$router.push( {
              name: 'service'
            })
          })
          .catch(err => console.log ('errorPUT', err))
        }   
      },
      getService() {
        if(this.ulid){
          this.showloader = true
          axios.get(`services/${this.ulid}`)
            .then(res => {
              console.log ('get response service',res.data),
              //insert result in data
              this.name= res.data.name
              this.tags= res.data.tags[0]
              this.description= res.data.description
              this.price= res.data.price
              this.duration= this.calculTimes(res.data.duration)
              this.category = res.data.category.ulid
              this.showloader = false

              })
            .catch(err => console.log('errorgetService',err))
        }
        
      },
      async showSnotify ()  {
        this.$snotify.success('Ajouté avec succès', {
          timeout: 2000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          
        });
      },
      getAllService(){
        axios.get('services')
          .then(
            res => {
              let resul = res.data.result
              resul.forEach(ser => {
                ser.tags.forEach(element => {
                  if (this.tagsservice.includes(element)) {
                    //some
                  }else{
                    this.tagsservice.push(element)
                  }
                });
              });
              
            }
          )
          .catch()
      },
      TimeToFloat(time){
        // cette méthode est à revoir
        function decimalAdjust(type, value, exp) {
        // Si l'exposant vaut undefined ou zero...
        if (typeof exp === 'undefined' || +exp === 0) {
          return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // Si value n'est pas un nombre
            // ou si l'exposant n'est pas entier
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
          return NaN;
        }
        // Décalage
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Re "calage"
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
      }

      // Arrondi décimal
      if (!Math.round10) {
        Math.round10 = function(value, exp) {
          return decimalAdjust('round', value, exp);
        };
      }
      // Arrondi décimal inférieur
      if (!Math.floor10) {
        Math.floor10 = function(value, exp) {
          return decimalAdjust('floor', value, exp);
        };
      }
      // Arrondi décimal supérieur
      if (!Math.ceil10) {
        Math.ceil10 = function(value, exp) {
          return decimalAdjust('ceil', value, exp);
        };
      }
        const h = Number(time.substr(0,2))
        const m = Number(time.substr(3,2)) /60
        let floatTime = h + m
        console.log('floatTime',floatTime)
        console.log('floatTime convertit',Math.ceil10(floatTime, -2))
        return Math.ceil10(floatTime, -2)
        // Version taloha (tsy précis ny 00:50)
        // const h = Number(time.substr(0,2))
        // const m = Number(time.substr(3,2)) /60
        // let floatTime = h + m
        // console.log(floatTime)
        // return floatTime.toFixed(2)
      },
      calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
  },
  mounted () {
    this.ulid=this.$route.params.ulid
    console.log(this.ulid)
        //get categories to form
    axios.get('categories')
    .then(res=>{
      this.categories=res.data.result
      this.isload = false
    })
    .catch(err=> console.log('err cat',err))
    //charge product via methods
    this.getService()
    this.getAllService()
  }
}
</script>